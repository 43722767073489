<template>
  <Header />
  <PageHeader :title="$t('pageNotifications.title')" />

  <div class="notifications-page">
  
      <!-- Bloc des notifications -->
      <div class="notifications-container">
      <ul v-if="notifications.length">
          <li
          v-for="(notification, index) in paginatedNotifications"
          :key="index"
          :class="{ 'notification-item': true, 'unread': !notification.is_read }"
          @click="openModal(notification)"
          >
          <div class="notification-icon">
              <i :class="['fas', notification.icon]"></i>
          </div>
          <div class="notification-details">
              <h3>{{ notification.title }}</h3>
              <small>{{ formatDateTime(notification.created_at) }}</small>
          </div>
          <button v-if="!notification.is_read" @click.stop="markAsRead(notification.id)">
              <span v-if="!loadingId || loadingId !== notification.id">
              <i class="fas fa-check-circle"></i> Lue
              </span>
              <span v-else>
              <i class="fas fa-spinner fa-spin"></i>
              </span>
          </button>
          </li>
      </ul>
      <p v-else class="no-notification">Aucune notification pour le moment.</p>
      </div>

      <!-- Bloc de pagination -->
      <div class="pagination-container">
      <div class="pagination" v-if="notifications.length">
          <button @click="prevPage" :disabled="currentPage === 1">Précédent</button>
          <span>Page {{ currentPage }} sur {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">Suivant</button>
      </div>
      </div>
  </div>



    <!-- Modal pour afficher les détails de la notification -->
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <div class="modal-header">
          <h3>{{ currentNotification.title }}</h3>
          <i :class="['fas', currentNotification.icon]" class="modal-icon"></i>
        </div>
        <div class="modal-body">
          <p><strong>Type:</strong> {{getTypeLabel(currentNotification.type)}}</p>
          <p><strong>Date:</strong> {{ formatDateTime(currentNotification.created_at) }}</p>
          <p><strong>Contenu:</strong> <span v-html="currentNotification.content"></span></p>
        </div>
        <button class="close-modal" @click="closeModal">Fermer</button>
      </div>
    
  </div>
  <Footer :key="footerKey" />
</template>

<script>
import Header from './shared/HeaderPage.vue';
import Footer from './shared/FooterPage.vue';
import PageHeader from './shared/PageHeader.vue';
import axios from 'axios';
import { SERVER_IP } from '../config';

import { formatDateTime, checkAuth } from '@/utils/validationUtils';

import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

export default {
  name: 'NotificationsPage',
  mixins: [refreshFooterMixin],
  components: {
    Header,
    PageHeader,
    Footer,
  },

  data() {
      return {
          notifications: [],
          showModal: false,
          currentNotification: null,
          loadingId: null,
          currentPage: 1, // Page actuelle
          itemsPerPage: 5, // Nombre de notifications par page
          footerKey: 0, //Initialisation de la clé pour Footer
      };
  },

  computed: {
      paginatedNotifications() {
          const start = (this.currentPage - 1) * this.itemsPerPage;
          const end = start + this.itemsPerPage;
          return this.notifications.slice(start, end);
      },
      totalPages() {
          return Math.ceil(this.notifications.length / this.itemsPerPage);
      },
  },

  created() {
      checkAuth();
      this.fetchNotifications();
  },

  methods: {
    async fetchNotifications() {
      try {
          const userId = checkAuth();
          const response = await axios.get(`${SERVER_IP}/notifications/get-notifications/${userId}`);
          this.notifications = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des notifications', error);
      }
    },
    async markAsRead(notificationId) {
      this.loadingId = notificationId;
      const token = localStorage.getItem('token'); // Récupérer le token depuis le stockage local

      try {
        const response = await axios.post(`${SERVER_IP}/notifications/mark-as-read`, { 
                notificationId, 
                token 
            });
        /*this.notifications = this.notifications.map((notification) =>
          notification.id === notificationId ? { ...notification, is_read: true } : notification
        );*/
        // Marquer la notification comme lue
        this.notifications = this.notifications.map((notification) =>
          notification.id === notificationId ? { ...notification, is_read: true } : notification
        );

        // Trouver l'index de la notification
        const notificationIndex = this.notifications.findIndex(n => n.id === notificationId);

        // Si l'index est trouvé
        if (notificationIndex !== -1) {
          // Vérifier si la notification contient un lien <a href=
          const containsLink = this.notifications[notificationIndex].content.includes('lien suivant');

          // Si la notification ne contient pas de lien, la supprimer du tableau
          if (!containsLink) {
            this.notifications.splice(notificationIndex, 1);
          }
        }
        
        const newToken = response.data.token; // Assurez-vous que votre serveur renvoie le token dans cette clé
        localStorage.setItem('token', newToken);
        this.footerKey++;

        

      } catch (error) {
        console.error('Erreur lors de la mise à jour de la notification', error);
      } finally {
        this.loadingId = null; // Réinitialiser l'ID en cours de chargement
      }
    },
    openModal(notification) {
      this.currentNotification = notification;
      this.showModal = true;
      if (!notification.is_read) {
        this.markAsRead(notification.id);
      }
    },
    closeModal() {
      this.showModal = false;
      this.currentNotification = null;
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    prevPage() {
      if (this.currentPage > 1) {
      this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
      this.currentPage++;
      }
    },
    getTypeLabel(type) {
      switch (type) {
          case 'Deposit':
              return 'Dépôt';
          case 'Withdraw':
              return 'Retrait';
          case 'Transfer':
              return 'Transfert';
          default:
              return type; // Retourne la valeur passée telle qu'elle est
      }
    },

  },
};
</script>

<style scoped>
.notifications-page {
display: flex;
flex-direction: column; /* Disposition verticale */
align-items: center; /* Centre les éléments horizontalement */
max-width: 400px;
width: 100%;
margin: 0 auto;
padding: 10px;
}

  .notification-item {
      display: flex;
      align-items: center;
      padding: 15px;
      width: 100%; /* Prendre toute la largeur de notifications-container */
      min-width: 380px;
      background-color: #ffffff;
      margin-bottom: 10px;
      margin-left: -45px;
      cursor: pointer;
      border-bottom: 1px solid #ddd;
  }


.notification-icon {
  margin-right: 15px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle, #e0e0e0, #b3b3b3);
  border-radius: 50%;
}

.notification-icon i {
  font-size: 24px;
  color: #016a40;
}

.notification-details {
  flex: 1;
  text-align: left;
}

.notification-details h3 {
  margin: 0;
  font-size: 18px;
  color: #000000; /* Titre en noir */
}

.notification-details small {
  color: #555555;
  font-size: 12px;
}

.notification-item.unread {
  background-color: #ffefc4;
}

button {
  padding: 8px 15px;
  background-color: #016a40;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #014d2d;
}

/* Icône de chargement pour le bouton */
button .fa-spinner {
  font-size: 16px;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #29293d;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: left;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal-icon {
  font-size: 36px;
  color: #016a40;
}

.modal-body {
  margin-bottom: 20px;
}

.close-modal {
  background-color: #016a40;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.close-modal:hover {
  background-color: #014d2d;
}

.pagination-container {
  width: 100%;
  }

  .pagination {
  display: block; /* Pour que la pagination prenne toute la largeur */
  text-align: center; /* Centre le contenu de la pagination */
  margin-top: 20px; /* Espace entre les blocs */
  }

  .pagination button {
  padding: 8px 15px;
  background-color: #016a40;
  color: white;
  border: none;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  }

  .pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  }

  .no-notification{
      color: #FFF;
  }
</style>
