<template>
  <Header />
  <PageHeader title="Mes Transactions" />

  <div class="mytransactions-page">

    <div class="search-container">
        <!-- Input de recherche -->
        <div class="search-input">
            <input 
                type="text" 
                v-model="searchQuery" 
                placeholder="Nom/Phone/Email" 
                @keyup.enter="searchTransactions"
            />
            <!-- Icône de recherche à l'intérieur de l'input -->
            <button @click="searchTransactions" class="search-icon">
                <i class="fas fa-search"></i>
            </button>
        </div>

        <!-- Bouton pour les filtres avec icône uniquement -->
        <button class="filter-btn"  @click="openFiltersModal">
            <i class="fas fa-sliders-h"></i>
        </button>

    </div>

    <div class="case-load" v-if="activeIndex==0">
      <i class="fas fa-spinner fa-spin"></i>
    </div>

    <div class="case-result" v-if="activeIndex==1">
        <!-- Résultats -->
        <div v-if="results.length > 0"> 
          <ul>
            <li v-for="(result, index) in paginatedResults" :key="result.id">
              <div class="container-transaction">
                <div class="left-section">
                  <div class="amount">
                    {{ formatCurrency(result.amount, result.currency, 'fr-HT') }} ({{ getTypeTransaction(result.transaction_type) }})
                  </div>
                  <div class="details">
                    <i>{{ formatDateTime(result.created_at) }}</i> - <span class="status">{{ getStatusDisplay(result.status) }}</span>
                  </div>
                </div>
                <div class="right-section" @click="showDetails(result.transaction_id)">
                  <div class="icon-wrapper">
                    <i class="fas fa-eye"></i>
                  </div>
                </div>
              </div>
              
              <!-- Ligne de séparation uniquement si ce n'est pas la dernière transaction -->
              <div v-if="index < paginatedResults.length - 1" class="separator"></div>
            </li>
          </ul>
        </div>


        <!-- Pagination -->
        <div v-if="totalPages > 1" class="pagination" v-show="false">
          <button
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </div>

        <div v-if="totalPages2 > 1" class="pagination">
          <button
            v-for="page in totalPages2"
            :key="page"
            :class="{ active: currentPage2 === page }"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </div>

        <!-- Indication s'il n'y a pas de résultats -->
        <p v-if="results.length <= 0">Aucun résultat trouvé</p>
        <div id="paginationHander"></div>
    </div>

    <div class="transaction-details" v-if="activeIndex == 2">
      <!-- Croix pour fermer -->
      <div class="close-button" @click="closeDetails">
        <i class="fas fa-times"></i>
      </div>

      <!-- Afficher un loader si la transaction n'est pas encore chargée -->
      <div v-if="transaction == null" class="loading-spinner">
        <i class="fas fa-spinner fa-spin"></i> Chargement des détails de la transaction...
      </div>

      <!-- Contenu des détails de la transaction (si transaction est chargée) -->
      <div v-else>
        <!-- Section transaction type et code -->
        <div class="transaction-header">
          <div class="transaction-type">
            {{ getTypeTransaction(transaction.transaction_type) }}
            <span v-if="transaction.isAgent !== ''"> ({{ transaction.isAgent }})</span>
          </div>

          <div class="transaction-code">#{{ transaction.code }}</div>
        </div>

        <!-- Montant et Frais -->
        <div class="transaction-info">
          <div class="info-row">
            <span class="label">Montant:</span>
            <span class="value">{{ transaction.amount }} HGT</span>
          </div>
          <div class="info-row">
            <span class="label">Frais:</span>
            <span class="value">{{ transaction.fees }} HGT</span>
          </div>
        </div>

        <!-- Statut de la transaction -->
        <div class="transaction-status" :class="statusClass(transaction.status)">
          {{ transaction.statusDisplay }} {{ transaction.status }}
        </div>

        <!-- Bénéficiaire et date -->
        <div class="transaction-info">
          <div class="info-row">
            <span class="label">{{ getLabelDetails(transaction.isAgent) }}:</span>

            <span class="value">
              <div>{{ transaction.beneficiaryName }}</div>
              <div>{{ transaction.beneficiaryPhone }}</div>
            </span>
          </div>
          <div class="info-row">
            <span class="label">Date:</span>
            <span class="value">{{ formatDateTime(transaction.date) }}</span>
          </div>
        </div>

        <!-- Annuler ou confirmer la transaction selon les conditions -->
        <div v-if="canCancelTransaction(transaction)" class="cancel-link">
          <a href="#" @click="handleTransactionAction(transaction)">
            {{ transactionActionText(transaction) }}
          </a>
        </div>


        <!-- Bouton pour télécharger le reçu -->
        <button class="download-receipt" @click="downloadReceipt(transaction.id)" :disabled="isDownloading">
          <span v-if="isDownloading">
            Téléchargement en cours <i class="fas fa-spinner fa-spin"></i>
          </span>
          <span v-else>
            Télécharger le reçu
          </span>
        </button>



      </div>
    </div>

    <!-- Modal pour les filtres -->
    <modal-filters-historic 
            v-if="showFiltersModal" 
            :isOpen="showFiltersModal" 
            @apply-filters="handleApplyFilters" 
            @close="closeFiltersModal" 
        />

  </div>

  <Footer :key="footerKey" />
</template>

<script>
    import Header from '../shared/HeaderPage.vue';
    import Footer from '../shared/FooterPage.vue';
    import PageHeader from '../shared/PageHeader.vue';

    import ModalFiltersHistoric from './ModalFiltersHistoric.vue';

    import { formatDateTime, formatCurrency, checkAuth } from '@/utils/validationUtils';
    import axios from 'axios';
    import { SERVER_IP } from '../../config';

    import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

    export default {
        name: 'HistoricTransactions',
        mixins: [refreshFooterMixin],
        components: {
            Header,
            PageHeader,
            ModalFiltersHistoric, // Doit correspondre à l'import
            Footer
        },

        created(){
            checkAuth();
        },

        data() {
            return {
                searchQuery: '',
                showFiltersModal: false, // Contrôle du modal
                selectedFilters: {
                  singleDate: null,  // Pour date unique
                  startDate: null,   // Pour la plage de dates (début)
                  endDate: null,      // Pour la plage de dates (fin)
                  allTypes: true,
                  selectedTypes: [],
                  allStatuses: true,
                  selectedStatuses: [],
                  allCurrencies: true,
                  selectedCurrencies: [],
                  rangeChoosen: false,
                },
                results: [], // Liste complète des résultats récupérés
                totalResults: 0, // Nombre total de résultats récupérés par l'API
                resultsPerPage: 4, // Nombre de résultats à afficher par page
                currentPage: 1, // Page actuelle
                totalPages: 0, // Nombre total de pages
                totalPages2: 0,
                activeIndex: 2,
                currentPage2: 1,
                transaction: null,
                isDownloading: false,
            };
        },

        mounted(){
          this.initiateFilters();
          this.searchTransactions();
        },
        beforeUnmount() {
          if (localStorage.getItem('savedFilters')) {
            localStorage.removeItem('savedFilters');
            console.log('savedFilters supprimé avant de quitter la page');
          }
        },

        computed: {
          paginatedResults() {
            const start = (this.currentPage - 1) * this.resultsPerPage;
            const end = start + this.resultsPerPage;
            const paginated = this.results.slice(start, end);
            return paginated;
          }
        },

        methods: {
            openFiltersModal() {
                this.showFiltersModal = true;
            },
            closeFiltersModal() {
                this.showFiltersModal = false;
            },
            handleApplyFilters(filters) {
                this.selectedFilters = filters;
                this.searchTransactions();
            },
            initiateFilters() {
              const today = new Date().toISOString().substr(0, 10);
              this.selectedFilters.singleDate = today;
              this.selectedFilters.startDate = today;
              this.selectedFilters.endDate = today;
              localStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
            },
            
            formatCurrency(amount, currency, locale) {
              return formatCurrency(amount, currency, locale);
            },

            formatDateTime(isoDateString){
              return formatDateTime(isoDateString);
            },

            getTypeTransaction(type) {
              switch (type) {
                case 'transfer':
                  return 'Transfert';
                case 'deposit':
                  return 'Dépôt';
                case 'withdrawal':
                  return 'Retrait';
                default:
                  return 'Type inconnu';
              }
            },

            getStatusDisplay(status) {
              switch (status) {
                case 'pending':
                  return 'En attente';
                case 'completed':
                  return 'Complétée';
                case 'failed':
                  return 'Echouée';
                case 'canceled':
                  return 'Annulée';
                default:
                  return 'Statut inconnu';
              }
            },

             // Méthode principale de recherche
            async searchTransactions() {
              try {

                // 1. Faire un appel API pour obtenir le nombre total de résultats
                const response = await this.fetchSearchResults();
                this.totalResults = response.data.total;
                this.results = response.data.data;

                // 2. Calculer le nombre total de pages
                this.totalPages = Math.ceil(this.totalResults / this.resultsPerPage);
                this.totalPages2 = this.totalPages;
                // 3. Charger les résultats de la première page (par défaut)
                this.currentPage = 1;
                // console.log(`Total results: ${this.totalResults}, Total pages: ${this.totalPages}`);

              } catch (error) {
                console.error('Erreur lors de la recherche:', error);
              }
            },

            // Simuler un appel API (vous pouvez remplacer cette méthode par un véritable appel API)
            async fetchSearchResults(page = 1) {
              this.activeIndex = 0;
              this.updateSelectedTypes();
              this.updateSelectedStatuses();
              let response;

              const url = `${SERVER_IP}/basic-withdraw/transaction-list`;
              try {
                  const userId = checkAuth();
                  response = await axios.post(url, {
                      userId: userId,
                      page: page,
                      searchQuery: this.searchQuery,
                      qtyPerPage: this.resultsPerPage,
                      allTypes: this.selectedFilters.allTypes,
                      selectedTypes: this.selectedFilters.selectedTypes,
                      allStatuses: this.selectedFilters.allStatuses,
                      selectedStatuses: this.selectedFilters.selectedStatuses,
                      allCurrencies: this.selectedFilters.allCurrencies,
                      selectedCurrencies: this.selectedFilters.selectedCurrencies,

                      rangeChoosen: this.selectedFilters.rangeChoosen,
                      singleDate: this.selectedFilters.singleDate,  
                      startDate: this.selectedFilters.startDate,  
                      endDate: this.selectedFilters.endDate
                  });

              }catch (error) {
                  this.activeIndex = 0;
              } finally {
                this.activeIndex = 1;
              }
              // Dans une vraie situation, vous ferez un appel HTTP à votre API.
              return response;
            },

            // Changer de page et charger les résultats correspondants
            async changePage(page) {
              try {
                // Mettre à jour la page courante
                this.currentPage = page;

                // Récupérer les résultats de la nouvelle page
                const response = await this.fetchSearchResults(page);

                if (response && response.data) {
                  // Assigner directement les nouveaux résultats
                  this.results = response.data.data;  
                  this.totalResults = response.data.total;
                  this.totalPages = Math.ceil(this.totalResults / this.resultsPerPage);
                  this.currentPage2 = page;
                  this.currentPage = 1;
                  const element = document.getElementById('paginationHander');
                  element.scrollIntoView({ behavior: 'smooth' });
      
                } else {
                  console.error(`Pas de données trouvées pour la page ${page}`);
                }
              } catch (error) {
                console.error(`Erreur lors du changement de page: ${error}`);
              }
            },

            async showDetails(transactionId) {
              // Réinitialiser la transaction et afficher le loader
              this.transaction = null; 
              this.activeIndex = 2; // Indique que les détails sont en cours de chargement

              const url = `${SERVER_IP}/basic-withdraw/transaction-details`;

              try {
                // Vérification si l'utilisateur est authentifié
                const userId = checkAuth();
                
                // Effectuer la requête API pour récupérer les détails de la transaction
                const response = await axios.post(url, {
                  userId: userId,
                  transactionId: transactionId
                });

                // Vérifier si la réponse contient des données avant de les affecter
                if (response && response.data) {
                  this.transaction = response.data.details;
                } else {
                  console.error('Les données de la transaction sont vides ou incorrectes.');
                }

              } catch (error) {
                console.error("Erreur lors du chargement des détails de la transaction:", error);
              }
            },
                        
            // details de transaction
            closeDetails() {
              this.activeIndex = 1;
            },
            statusClass(status) {
              switch (status) {
                case 'completed':
                  return 'success';
                case 'failed':
                  return 'failed';
                case 'pending':
                  return 'pending';
                case 'canceled':
                  return 'cancelled';
              }
            },

            getLabelDetails(isAgent) {
              if (isAgent === 'Client') {
                return 'Agent';
              } else if (isAgent === 'Agent') {
                return 'Client';
              } else if (isAgent === 'Bénéficiaire') {
                return 'Transférant';
              } else if (isAgent === 'Transférant') {
                return 'Bénéficiaire';
              } else {
                return 'Bénéficiaire'; // Valeur par défaut
              }
            },

            // Méthode pour gérer l'action de la transaction (annuler ou confirmer)
            handleTransactionAction(transaction) {
              if (transaction.isAgent === 'Client' && this.isTransactionOlderThan5Minutes(transaction.date)) {
                // Annuler la transaction
                this.cancelTransaction(transaction.id);
              } else if (transaction.isAgent === 'Agent') {
                // Confirmer la transaction
                this.confirmTransaction(transaction.id, transaction.transaction_type);
              }
            },

            // Méthode pour annuler la transaction
            cancelTransaction(id) {
              // Redirection ou logique d'annulation de la transaction
              this.$router.push(`/tracking?ref=${id}`);
            },

            // Méthode pour confirmer la transaction
            confirmTransaction(id, transactionType) {
              let confirmLink = '';

              // Vérifier le type de la transaction
              if (transactionType === 'deposit') {
                confirmLink = `/confirm-depot?ref=${id}`;
              } else if (transactionType === 'withdrawal') {
                confirmLink = `/confirm-retrait?ref=${id}`;
              } else {
                console.error("Type de transaction non pris en charge");
                return;
              }

              // Redirection vers le lien approprié
              this.$router.push(confirmLink);
              console.log(`Transaction ${id} confirmée, redirection vers ${confirmLink}`);
            },

            // Méthode pour vérifier si la transaction est plus vieille que 5 minutes
            isTransactionOlderThan5Minutes(transactionDate) {
              const now = new Date();
              const transactionTime = new Date(transactionDate);
              const differenceInMinutes = (now - transactionTime) / 60000; // Convertir en minutes
              return differenceInMinutes > 5;
            },

            // Méthode pour déterminer le texte à afficher pour l'action
            transactionActionText(transaction) {
              if (transaction.isAgent === 'Client' && this.isTransactionOlderThan5Minutes(transaction.date)) {
                return 'Annuler la transaction';
              } else if (transaction.isAgent === 'Agent') {
                return 'Confirmer la transaction';
              } else {
                return ''; // Ne rien afficher si aucune condition n'est remplie
              }
            },

            // Méthode pour vérifier si la transaction peut être annulée
            canCancelTransaction(transaction) {
              // Vérifiez les conditions où la transaction peut être annulée
              return transaction.status === 'pending';
            },

            updateSelectedTypes() {
              if (this.selectedFilters.selectedTypes && this.selectedFilters.selectedTypes.length > 0) {
                this.selectedFilters.selectedTypes = this.selectedFilters.selectedTypes.map(type => {
                  switch (type) {
                    case 'Dépôt':
                      return 'deposit';
                    case 'Retrait':
                      return 'withdrawal';
                    case 'Transfert':
                      return 'transfer';
                    default:
                      return type; // Si le type n'est pas reconnu, on le garde tel quel
                  }
                });
              }
            },

            updateSelectedStatuses() {
              if (this.selectedFilters.selectedStatuses && this.selectedFilters.selectedStatuses.length > 0) {
                this.selectedFilters.selectedStatuses = this.selectedFilters.selectedStatuses.map(status => {
                  switch (status) { 
                    case 'Complet':
                      return 'completed';
                    case 'En attente':
                      return 'pending';
                    case 'Annulé':
                      return 'canceled';
                    default:
                      return status; // Si le type n'est pas reconnu, on le garde tel quel
                  }
                });
              }
            },

            async downloadReceipt(transactionId) {
              this.isDownloading = true;
              try {
                console.log(`Télécharger le reçu de la transaction ${transactionId}`);

                // Envoi de la requête POST à l'API pour générer le PDF
                const url = `${SERVER_IP}/basic-withdraw/download-receipt`;
                const userId = checkAuth();
                const response = await axios.post(
                  url, 
                  { userId: userId, transactionId: transactionId },
                  { responseType: 'blob' }  // Indiquer que la réponse est un fichier (blob)
                );

                // Vérification si la réponse contient un fichier PDF
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                
                // Créer un lien temporaire et le simuler pour le téléchargement
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `transaction_${transactionId}.pdf`;  // Nom du fichier à télécharger
                document.body.appendChild(link);
                link.click();  // Simuler un clic pour télécharger le fichier
                document.body.removeChild(link);  // Nettoyage après le téléchargement

                console.log('Téléchargement du PDF réussi');
              } catch (error) {
                console.error('Erreur lors du téléchargement du PDF:', error);
                alert('Erreur lors du téléchargement du fichier PDF');
              } finally {
                this.isDownloading = false;
              }
            },
            
          },
        

    };


</script>

<style scoped>
.mytransactions-page {
  text-align: center;
  max-width: 400px;
  margin: auto;
  margin-top: 50px;
  color: #FFFFFF;
}

/* Style du conteneur */
.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Style de l'input de recherche */
.search-input {
  position: relative;
  flex-grow: 1;
  margin-right: 15px; /* Espace entre l'input et le bouton filters */
  max-width: 75%;
}

.search-input input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-input input::placeholder {
  color: lightgray; /* Placeholder gris clair */
}

/* Icône de recherche à l'intérieur de l'input */
.search-input .search-icon {
  position: absolute;
  right: -15%;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: black; /* Couleur de l'icône de recherche */
  cursor: pointer;
  font-size: 18px;
}

/* Bouton de filtre */
.filter-btn {
  padding: 10px;
  background-color: white; /* Arrière-plan blanc */
  color: black; /* Texte noir */
  border: none;
  border-radius: 5px;
  font-size: 16px; /* Taille de l'icône */
  cursor: pointer;

}

.filter-btn i {
  margin: 0;
}

.modal-filters-historic{
  margin-bottom: 20px;
}

.case-load{
  font-size: 3rem;
  margin-top: 50%;
}

.case-result ul li{
  list-style-type: none;
  text-align: left;
  margin-left: -10%;
}


.pagination {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  padding-bottom: 15%;
}

.pagination button {
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border: none;
}


.container-transaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}

.left-section {
  width: 100%;
  padding-left: 0; /* Retire le padding/margin à gauche */
  color: white;
}

.left-section .amount {
  font-size: 15px;
  font-weight: normal;
}

.left-section .details {
  font-size: 10px;
  color: #ccc; /* Gris clair pour le sous-texte */
}

.left-section .details i {
  font-style: italic;
}

.right-section {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon-wrapper {
  background-color: white;
  border-radius: 50%;
  padding: 10px;
}

.icon-wrapper i {
  color: black;
  font-size: 18px;
}

/* Ligne de séparation entre deux transactions */
.separator {
  width: 100%;
  height: 1px;
  background-color: grey;
  margin: 10px 0; /* Ajoute un petit espace en haut et en bas */
}

/***************************************************************/
/* details transaction  */
.transaction-details {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 340px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 20%;
}

/* Croix de fermeture */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-button i {
  font-size: 20px;
  color: black;
}

/* En-tête de la transaction */
.transaction-header {
  text-align: center;
  background-color: black;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 15px;
  border-radius: 5px;
}

.transaction-type {
  font-size: 14px;
}

.transaction-code {
  font-size: 12px;
  font-style: italic;
  color: lightgray;
}

/* Infos de la transaction */
.transaction-info {
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
}

.value {
  text-align: right;
}

/* Statut de la transaction */
.transaction-status {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  color: white;
  margin-bottom: 20px;
}

.transaction-status.success {
  background-color: green;
}

.transaction-status.failed {
  background-color: red;
}

.transaction-status.pending {
  background-color: orange;
}

.transaction-status.cancelled {
  background-color: gray;
}

/* Annuler la transaction */
.cancel-link {
  text-align: center;
  margin-bottom: 20px;
}

.cancel-link a {
  color: red;
  text-decoration: underline;
  font-size: 14px;
}
.cancel-link a:hover{
  text-decoration: none;
}

/* Bouton de téléchargement */
.download-receipt {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #016a40;
  color: white;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.download-receipt:hover {
  background-color: #005934;
}

.transaction-details .loading-spinner {
  text-align: center;
  margin: 20px 0;
  font-size: 1.2em;
  color: #555;
  margin-top: 35%;
  margin-bottom: 35%;
}


</style>
