<template>
    <Header />
    <PageHeader title="Taux Du Jour" />

    <div class="daily-rates-page">

        <div class="select-container">
            <div class="select-group">
                <label for="department-select"><i class="fas fa-map-marker-alt icon-bg"></i> Département</label>
                <select id="department-select" v-model="selectedDepartment" @change="updateCities">
                    <option v-for="department in departments" :key="department.id" :value="department.id">
                    {{ department.name }}
                    </option>
                </select>
            </div>

            <div class="select-group">
                <label for="city-select"><i class="fas fa-city  icon-bg"></i> Ville</label>
                <select id="city-select" v-model="selectedCity">
                    <option v-for="city in cities" :key="city">
                    {{ city }}
                    </option>
                </select>
            </div>

            <div class="select-group">
                <span class="favorites"><i class="fas fa-heart"></i></span>
            </div>
        </div>
    </div>

    

    <Footer :key="footerKey" />
</template>

<script>
  //import axios from 'axios';
  import Header from '../shared/HeaderPage.vue';
  import Footer from '../shared/FooterPage.vue';
  import PageHeader from '../shared/PageHeader.vue';

  import { refreshFooterMixin } from '@/mixins/refreshFooterMixin';

  export default {
    name: 'DailyRates',
    mixins: [refreshFooterMixin],
    components: {
      Header,
      PageHeader,
      Footer
    },

    data () {
        return {
            departments: [
                { id: 1, name: 'Artibonite' },
                { id: 2, name: 'Centre' },
                { id: 3, name: 'Grand\'Anse' },
                { id: 4, name: 'Nippes' },
                { id: 5, name: 'Nord' },
                { id: 6, name: 'Nord-Est' },
                { id: 7, name: 'Nord-Ouest' },
                { id: 8, name: 'Ouest' },
                { id: 9, name: 'Sud' },
                { id: 10, name: 'Sud-Est' }
            ],
            citiesByDepartment: {
                1: [
                'Gonaïves', 'Saint Marc', 'Petite-Rivière-de-l\'Artibonite', 'Gros-Morne',
                'Saint-Michel-de-l\'Attalaye', 'Desdunes', 'Dessalines', 'L’Estère', 'Verrettes'
                ],
                2: [
                'Hinche', 'Mirebalais', 'Thomassique', 'Maïssade', 'Thomonde', 'Lascahobas'
                ],
                3: [
                'Jérémie', 'Anse-d\'Ainault'
                ],
                4: [
                'Miragoâne'
                ],
                5: [
                'Cap-Haïtien', 'Limbé', 'Limonade', 'Plaisance', 'Grande Rivière du Nord',
                'Saint-Raphaël', 'Pignon', 'Terrier-Rouge', 'Port-Margot', 'Dondon', 'Plaine-du-Nord',
                'Dame-Marie', 'Acul-du-Nord'
                ],
                6: [
                'Ouanaminthe', 'Trou-du-Nord', 'Fort-Liberté'
                ],
                7: [
                'Port-de-Paix', 'Saint-Louis-du-Nord', 'Jean Rabel'
                ],
                8: [
                'Port-au-Prince', 'Carrefour', 'Delmas', 'Pétionville', 'Cité Soleil',
                'Croix-des-Bouquets', 'Petit-Goâve', 'Léogâne', 'Tabarre', 'Cabaret', 'Anse-à-Galets',
                'Grand-Goâve', 'Arcahaie', 'Kenscoff', 'Gressier', 'Fonds-Verrettes', 'Thomazeau',
                'Belladère', 'Ganthier'
                ],
                9: [
                'Les Cayes', 'Aquin'
                ],
                10: [
                'Jacmel'
                ]
            },
            selectedDepartment: 8,
            selectedCity: '',
            cities: [],
        }
    },

    mounted() {
        this.updateCities(); // Mettre à jour les villes lors du montage
    },

    methods : {
        updateCities() {
            this.cities = this.citiesByDepartment[this.selectedDepartment] || [];
            this.selectedCity = this.cities[0] || '';
        },
        getDepartmentNameById(departmentId) {
            const department = this.departments.find(dept => dept.id === departmentId);
            return department ? department.name : 'Département inconnu';
        },
    }
    
  };

</script>

<style scoped>

.daily-rates-page {
  max-width: 400px;
  margin: auto;
  padding: 0px;
  text-align: center;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.8rem;
}

label {
  margin-bottom: 0.5em;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
}

.icon-bg {
  background-color: #016A40;
  color: white;
  padding: 5px; /* Taille des icônes réduite */
  border-radius: 8px; /* Coins arrondis */
  margin-right: 10px;
  font-size: 0.6em; /* Réduire la taille des icônes */
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 0.9em;
}

.select-container {
  display: flex;
  gap: 1em; /* Espace entre les éléments */
  margin-bottom: 1em;
}

.select-group {
  display: flex;
  flex-direction: column;
  width: 200px; /* Largeur fixe pour les éléments select */
}

select {
  padding: 0.5em;
  font-size: 1em;
  background-color: black;
  border: 2px solid #FFF;
  color: white;
  border-radius: 4px;
  outline: none;
}

select:focus {
  border-color: #016A40; /* Bordure plus claire lors du focus */
}

label {
  margin-bottom: 0.5em;
  color: white;
}
</style>